import React from 'react'
import { useState } from "react"
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'

const EditBalance = ({balance, onUpdate}) => {
    const [splurge, setSplurge] = useState(balance.splurge)
    const [smile, setSmile] = useState (balance.smile)
    const [give, setGive] = useState(balance.give)
    const [description, setDescription] = useState('')
    const [id, setId] = useState(balance.id)


    const [newBalance, setNewBalance] = useState()


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [showAlert, setShowAlert] = useState(false)
    const handleCloseAlert = () => setShowAlert(false)
    const handleShowAlert = (text) => {
        setErrorText(text)
        setShowAlert(true)
    }
    const [errorText, setErrorText] = useState('')

    const handleSave = () => {
        handleClose()
        onUpdate(newBalance)
    }
    
const dollarEditStyle = {
    width: '70%',
  };

const onSubmit = (e) => {
    e.preventDefault()
     if(!splurge || !smile || !give) {
         //alert('Please specify correct values')
         handleShowAlert('Please specify correct values')
         return
     }
     if(!description){
         handleShowAlert('Please specify description')
         return
     }
     if(isNaN(splurge) || isNaN(smile) || isNaN(give)){
         //alert('Please specify numbers')
         handleShowAlert('Please specify numbers')
         return
     }
     
     setNewBalance(
         [
             {
                 id: id,
                 name: balance.name,
                 splurge: splurge,
                 smile: smile,
                 give: give,
                 description: description
             }
         ]
     )
     
     handleShow()
     
}


    return (
        <div>
            <form className='edit-form' onSubmit={onSubmit}>
                <h4>Edit {balance.name}</h4>
                <div className='header2'>
                    <div className='form-control'>
                        <label>Splurge</label>
                        $<input style={dollarEditStyle} type='text' placeholder={balance.splurge} onChange={(e) => setSplurge(e.target.value)} />
                    </div>
                    <div className='form-control'>
                        <label>Smile</label>
                        $<input style={dollarEditStyle} type='text' placeholder={balance.smile} onChange={(e) => setSmile(e.target.value)} />
                    </div>
                    <div className='form-control'>
                        <label>Give</label>
                        $<input style={dollarEditStyle} type='text' placeholder={balance.give} onChange={(e) => setGive(e.target.value)} />
                    </div>
                </div>
                <div className='form-control'>
                    <label>Description</label>
                    <input type='text' placeholder='Enter description' onChange={(e) => setDescription(e.target.value)}/>
                </div>
                <input type='submit' value='Save' className='btn' />
            </form>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to update the balance? 
                    <ul>
                        <li>Splurge: ${balance.splurge} -> ${splurge}</li>
                        <li>Smile: ${balance.smile} -> ${smile}</li>
                        <li>Give: ${balance.give} -> ${give}</li>
                        <li>Description: {description}</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAlert} onHide={handleCloseAlert}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAlert}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default EditBalance
