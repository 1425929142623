import React from 'react'
import Button from './Button'
import { Accordion } from 'react-bootstrap'
import EditBalance from './EditBalance'
import { useState } from "react"


const Balance = ({balance, onClick, refreshData, accessLevel}) => {
//               <Button color='green' text='Edit' onClick={() => onEdit(balance.id)} />
 

const [showEdit, setShowEdit] = useState(false)
const [buttonText, setButtonText] = useState('Edit')
const [buttonColor, setButtonColor] = useState('Green')

const toggleEdit = () => {
    setShowEdit(!showEdit)
    showEdit ? setButtonText('Edit') : setButtonText('Cancel')
    showEdit ? setButtonColor('green') : setButtonColor('red')
  }

// Edit Balance
//onUpdate({id,splurge,smile,give, description})
    const updateBalance = async (newBalance) => {

        console.log('newBalance ' + JSON.stringify(newBalance[0]))


        const res = await fetch(`https://2oo4aanb07.execute-api.ap-southeast-2.amazonaws.com/balances/{balance.id}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(newBalance[0])
        })
        const data = res.json()
        console.log(data)
        toggleEdit()
        refreshData()
    }

    return (
        <div>
            <header className='header'>
                <h2>{balance.name}</h2>
                {(accessLevel === 'edit') ? (<Button color={buttonColor} text={buttonText} onClick={toggleEdit}/> ) : ''}
            </header>
            <header className='header'>
                <p>Splurge</p>
                <h3 style={{ fontWeight: 'bold'}}>${balance.splurge}</h3>
                <p>Smile</p>
                <h3 style={{ fontWeight: 'bold'}}>${balance.smile}</h3>
                <p>Give</p>
                <h3 style={{ fontWeight: 'bold'}}>${balance.give}</h3>
            </header>
            
            {showEdit ? (<EditBalance balance={balance} onUpdate={updateBalance} />) : ''}

            <p/>
        </div>
    )
}
export default Balance
