import React from 'react'

const BalanceHistory = ({ balanceHistory}) => {
    const localDate = new Date(balanceHistory.date / 1).toLocaleString()
    return (
        <div className='balance-history'>
            <p style={{ fontWeight: 'bold'}}>{balanceHistory.name} - {localDate}</p>
            <div className='balance-history-money'>
                <p>Splurge: ${balanceHistory.splurge}</p>
                <p>Smile ${balanceHistory.smile}</p>
                <p>Give: ${balanceHistory.give}</p>
            </div>
            <p>{balanceHistory.description}</p>
        </div>
    )
}

export default BalanceHistory
