import React from 'react'
import Balance from './Balance'

const Balances = ({balances, refreshData, accessLevel}) => {
    console.log('Balances')
    return (
        <>
        {balances.map((balance) => (
            <Balance key={balance.id} balance={balance} refreshData={refreshData} accessLevel={accessLevel} />
        ))}
        </>
    )
}

export default Balances
