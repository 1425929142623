import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div className='container'>
           <h4>Version 1.0.0</h4>
           <p>willeo.net all rights reserved</p>
           <Link to="/">Go Back</Link> 
        </div>
    )
}

export default About