import React from 'react'
import BalanceHistory from './BalanceHistory'


const BalanceHistories = ({ balanceHistories}) => {
    console.log('BalanceHistories')
    return (
        <>
        {balanceHistories.map((balanceHistory) => (
            <BalanceHistory key={balanceHistory.key} balanceHistory={balanceHistory} />
        ))}
        </>
    )
}

export default BalanceHistories
