import { useState, useEffect } from 'react'
import Balances from './components/Balances';
import BalanceHistories from './components/BalanceHistories';
import Login from './components/Login';
import Footer from './components/Footer';
import About from './components/About';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom'

// Sorting function for Balances
const compare = (a, b) => {
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0
}

const compareHistory = (a, b) => {
  if (a.date < b.date) return 1;
  if (a.date > b.date) return -1;
  return 0
}

const setAccessLevel = (url) => {
  // Token handling
  const access_token = url.searchParams.get("access_token");
  const id_token = url.searchParams.get("id_token");
  let accessToken

  if (access_token != null) {
    const accessTokenArray = access_token.split(".")
    accessToken = {
      header: atob(accessTokenArray[0]),
      body: atob(accessTokenArray[1]),
      signature: accessTokenArray[2]
    }
    const idTokenArray = id_token.split(".")
    const idToken = {
      header: atob(idTokenArray[0]),
      body: atob(idTokenArray[1]),
      signature: idTokenArray[2]
    }
    const tokenBody = JSON.parse(idToken.body)
    if (tokenBody.email === 'shin78@gmail.com' || tokenBody.email === 'ccc298@gmail.com') {
      console.log("Admin mode - user : " + tokenBody.email)
      return 'edit'
    }
    return 'readOnly'
  }

  return 'none'
}

const App = ({ url }) => {


  // noAccess | readOnly | edit
  const accessLevel = setAccessLevel(url)
  console.log('App. Access level : ' + accessLevel)
  const [balances, setBalances] = useState([])
  const [balanceHistories, setBalanceHistories] = useState([])

  useEffect(() => {
    console.log('useEffect')
    refreshData()
  }, [])

  const getBalances = async () => {
    const balancesFromServer = await fetchBalances()
    setBalances(balancesFromServer)
  }
  const getHistories = async () => {
    const balanceHiostiesFromServer = await fetchHistories()
    //   console.log(balanceHiostiesFromServer)
    setBalanceHistories(balanceHiostiesFromServer)
  }
  const refreshData = () => {
    console.log('refreshData')
    getBalances()
    getHistories()
  }

  // Fetch Balances

  const fetchBalances = async () => {
    const res = await fetch('https://2oo4aanb07.execute-api.ap-southeast-2.amazonaws.com/balances')
    const data = await res.json()
    // console.log(data.Items)
    return data.Items.sort(compare)
  }

  // Fetch Balance Histories
  const fetchHistories = async () => {
    console.log('fetchHistories')
    const res = await fetch('https://cyqwyu8tif.execute-api.ap-southeast-2.amazonaws.com/histories')
    const data = await res.json()
    //   console.log(data.Items)
    return data.Items.sort(compareHistory)
  }

  // if(!token) {
  //   return <Login setToken={setToken} />
  // }

  return (
    <Router>
      <Routes>
        <Route path='/' exact element={
          <div className="container">
            <div className="header">
              <h1>Pocket Money</h1>
              <p>{(accessLevel === 'edit' || accessLevel === 'readOnly' ) ? <a href="https://willeo.auth.ap-southeast-2.amazoncognito.com/logout?client_id=1o0j3s269km70c83u970ej7i7&logout_uri=https://pocket-money.willeo.net&response_type=code">Log Out</a> : <a href="https://willeo.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=1o0j3s269km70c83u970ej7i7&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fpocket-money.willeo.net">Log In</a>}</p>
            </div>
            {balances.length > 0 ? (<Balances balances={balances} refreshData={refreshData} accessLevel={accessLevel} />) : ('No balances to show')}
            <h1>Balance History</h1>
            {balanceHistories.length > 0 ? (<BalanceHistories balanceHistories={balanceHistories} />) : ('No balance history to show')}
            <Footer />
          </div>
        }></Route>
        <Route path='/about' element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;


// use Location
// const location = useLocation()
// {location.pathname === '/' &&& ( do something)}

// Delete Task

// const deleteTask = (id) => {
//   // console.log('delete', id)
//   setTasks(tasks.filter((task) => task.id !== id))
// }


// Toggle Reminder

// const toggleRemnder = (id) => {
//   setTasks(tasks.map((task)=> 
//     task.id === id ? { ...task, reminder: !task.reminder } : task
//     )
//   )
// }
//      <Header  />
//<AddTask />
//{tasks.length > 0 ? (<Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleRemnder}/>) : ('No Tasks to Show')}
//      <EditBalance show={modalShow} onHide={() => setModalShow(false)} balance={balanceForEdit} />


  // const [balances, setBalances] = useState(
  //   [
  //     {
  //           id: 1,
  //           name: 'Will',
  //           splurge: 12,
  //           smile: 4, 
  //           give: 20,
  //     },
  //     {
  //           id: 2,
  //           name: 'Leo',
  //           splurge: 24,
  //           smile: 43,
  //           give: 4,
  //     }
  //   ]
  // )




  // const [balanceHistories, setBalanceHistories] = useState(
  //   [
  //     {
  //       id: 1,
  //       name: 'Will',
  //       splurge: 11,
  //       splurgeChanged: true,
  //       smile: 5, 
  //       smileChanged: false,
  //       give: 20,
  //       giveChanged: true,
  //       description: 'December Pocked Money',
  //       date: 'Feb 5th at 2:30pm',
  //       modifiedBy: 'shin78',
  // },
  // {
  //       id: 2,
  //       name: 'Leo',
  //       splurge: 23,
  //       splurgeChanged: false,
  //       smile: 25,
  //       smileChanged: false,
  //       give: 5,
  //       giveChanged: true,
  //       description: 'December Pocket Money',
  //       date: 'Feb 5th at 4:30pm',
  //       modifiedBy: 'ccc298',
  // }
  //   ]
  // )